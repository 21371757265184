<!--THIS IS TEMPORARY FOR EDIT DELIVERY. BECAUSE THE ORIGINAL DOES EDIT DO ONLY FROM SAP. NOT TESTED-->
<template>
    <b-overlay :show="loading" rounded="lg">
    <div>
        <b-breadcrumb style="margin-bottom:20px">
            <b-breadcrumb-item to="/">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                />
            </b-breadcrumb-item>
            <b-breadcrumb-item to="/delivery-orders/daikin" v-if="goBack==='do-daikin'">List Delivery Order Daikin</b-breadcrumb-item>
            <b-breadcrumb-item to="/delivery-orders/sumitomo" v-if="goBack==='do-sumitomo'">List Delivery Order Sumitomo</b-breadcrumb-item>
            <b-breadcrumb-item active>TEST EDIT Delivery Order</b-breadcrumb-item>
        </b-breadcrumb>
        <b-card no-body>
            <b-card-header> 
                <!-- 1. Common information this DO-->
                <h4 v-if="doData.shippingPoint==='1001' && goBack==='do-daikin'">Daikin Warehouse</h4>
                <h4 v-if="doData.shippingPoint==='1003' && goBack==='do-sumitomo'">Sumitomo Warehouse</h4>
            </b-card-header>

            <b-card-body>
                <b-col cols="12">
                <b-row>

                    <b-col cols="12">
                    <div class="d-flex flex-column">
                        <label>SAP DO Number</label>
                        <p class="font-weight-bold">{{draftDelivery.sapDo }}</p>
                    </div>
                    </b-col>

                    <b-col cols="12">
                    <div class="d-flex flex-column">
                        <label>SAP SO Number</label>
                        <p class="font-weight-bold">{{draftDelivery.sapSo }}</p>
                    </div>
                    </b-col>

                    <b-col cols="12">
                    <div class="d-flex flex-column">
                        <label>Delivery Date</label>
                        <date-picker
                        v-model="draftDelivery.deliveryDate"
                        :disabled-date="filterDisableDate"
                        type="date"
                        :use12h="true"
                        :show-second="false"
                        @pick="datePicked"/>
                        <p class="font-weight-bold">{{displayDate(draftDelivery.deliveryDate)  }}</p>
                    </div>
                    </b-col>

                    <b-col cols="12">
                    <div class="d-flex flex-column">
                        <label>Delivery Time</label> <!-- should from time data-->
                        
                    <b-form-select style="width: 210px" v-model="draftDelivery.deliveryTime" :options="timeslotOptions">
                        <b-form-select-option :value="null" disabled>-- Please select timeslot --</b-form-select-option>
                    </b-form-select>
                    </div>
                    </b-col>
                    <b-col cols="8">
                    <div class="d-flex flex-column">
                        <label>Delivery Address</label>
                        <b-form-input
                            v-model="draftDelivery.deliveryAddress1"
                            type="text"
                            placeholder="Enter Delivery Street"
                        ></b-form-input>
                        <b-form-input
                            v-model="draftDelivery.deliveryPostalCode"
                            type="text"
                            placeholder="Enter Postal Code"
                        ></b-form-input>
                        <!-- <p class="font-weight-bold">{{draftDelivery.deliveryAddress1 }}, {{draftDelivery.deliveryPostalCode }}</p> -->
                    </div>
                    </b-col>

                    <b-col cols="12">
                        <div class="d-flex flex-column">
                            <label>Business Partner</label>
                        <p class="font-weight-bold">{{ doData.CustomerName1 +" "+doData.CustomerName2 }}</p>
                        </div>
                    </b-col>
                    <b-col cols="12">
                        <div class="d-flex flex-column">
                        <label>Customer Information</label>
                        <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
                        <p class="font-weight-bold">{{ doData.phone }}</p>
                        </div>
                    </b-col>
                </b-row>
                </b-col>

                <b-col cols="12" class="mt-2">
                    <b-row>
                    <b-table
                        :fields="scanFields"
                        :items="doData.items"
                        show-empty
                    >                
                        <template #cell(material)="row">
                            {{getItem(row.item.material).itemModel}}
                        </template>
                        <template #cell(desc)="row">
                            {{getItem(row.item.material).description}}
                        </template>
                        <template #cell(scannedBarcode)="row">
                            {{ mapBarcode(row.item.material) }}
                        </template>
                        <template #cell(qty)="row">
                            <b-form-input
                            v-model="row.item.qty"
                            type="number"
                        ></b-form-input>
                        </template>
                    </b-table>
                    </b-row>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col cols="8">
                        </b-col>
                        <b-col cols="4">
                            <b-button :disabled="loading" @click="editDO" class="float-right" variant="success">Update</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card-body>
        </b-card>
    </div>
    </b-overlay>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations';
import { dateFormat } from '@/utils/utils.js';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    'date-picker': DatePicker,
    'v-select': vSelect,
  },
    data() {
        return {
            scanContext: null,
            scannedBarcode: '',
            
            doData: {
                items: [],
            },
            scanItemTables: [],
            scanFields: [
                { key: 'material', label: 'Item Model' },
                {key : 'desc', label:'Description'},
                { key: 'qty', label: 'Qty' },
                { key: 'scannedBarcode', label: 'Barcode'},
            ],
            barcodeList:'',
            
            draftDelivery: '',
            oriDelivery:'',
            DO_details:'',
            forCount:'',
            postal: [],
            itemCatalog:[],
            holidays: [],
            timeslotOptions: [],
            
            items: [],
            backendItems: [],

            loading : false,

            goBack:'', //this one variable to mark this page is opened from what table list?
    
        }
    },
    created() {
        this.goBack = this.$route.params.from
        this.getDraftDeliveryDetail().then((Detail)=>{
            let tableIndex = 0;
            this.scanItemTables = Detail.items.reduce((prev, next) => {

                let expandedQty = [];
                var barcodeDO =  next.scannedBarcode.map(x=>{return x.itemUsed})
                for (let i = 0; i < next.qty; i++) {
                    var tempBarcode ;
                    
                    if(barcodeDO[i]===undefined){
                        tempBarcode = ''
                    }else{
                        tempBarcode = barcodeDO[i];
                    }
                    expandedQty.push({ ...next, scannedBarcode: tempBarcode, qty: 1, index: tableIndex, skip: false, })
                    tableIndex++;
                }

                return [ ...prev, ...expandedQty ];
            }, []);

            this.doData = Detail;
            this.getItems();
        })
        
        //this.$store.dispatch("delivery/getDeliveryOrder");
        

        //temporary get api items catalog from axios
        /*
        axios.get('sales-and-purchase/drcc/items?total=100').then((response) => {
            this.itemCatalog = response.data.data;
            // console.log(this.itemCatalog)
        }).catch((e) => {
            console.log(e)
        })
        */
        //temporary get api post code from axios
        /*
        axios.get('sales-and-purchase/drcc/postal').then((response) => {
            this.postal = response.data.data;
        }).catch((e) => {
            console.log(e)
        })
        */

        },
    methods: {
        ...mapActions({
            updateDelivery: 'delivery/callbackUpdateDO',
        }),
        getItems(){
            var idList=this.doData.DO_details.map(x=>{return x.material});
            //temporary get api items catalog from axios
            var list= idList.join("|")
            console.info('getItems list ',list)
            axios
            .get("sales-and-purchase/drcc/items/multi?idList="+list)
            .then((response) => {
                this.itemCatalog = response.data.data;
                // console.log(this.itemCatalog)
            })
            .catch((e) => {
                console.log(e);
            });
            },
        dates(date){
            return dateFormat(date)
        },
        editDO(){
            this.loading = true
            var doDetails = [];
            this.draftDelivery.DO_details.forEach(element => {
                let hierarchy = this.itemCatalog.find(x=>{return x._id === element.material}) //temporary get hierarchy for phier field DO_Details
                doDetails.push({
                    lineitem: element.lineitem,
                    lineitemref:element.lineitemref?element.lineitemref:"3746",
                    material: hierarchy.itemModel,
                    qty: element.qty,
                    phier:hierarchy.productHierarchy,
                    custgroup:"FU",
                })
            });
            
            //date
            var orderDate = moment(this.draftDelivery.orderDate).format("DDMMYYYY");
            var deliveryDate = moment(this.draftDelivery.deliveryDate).format("DDMMYYYY");

            this.draftDelivery.orderDate = orderDate;
            this.draftDelivery.deliveryDate = deliveryDate;
            this.draftDelivery.DO_details = doDetails;
            this.draftDelivery.status="Edit Delivery";
            this.updateDelivery( this.draftDelivery )
            .then(()=>{
                this.$bvToast.toast('Successfully Edit Delivery', {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                this.loading = false

            })
            .catch((e) => {
                if (e.response.data !== undefined) {
                    this.$bvToast.toast(e.response.data.message, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    });
                    this.loading = false
                }else{
                    this.$bvToast.toast("Connection error. Please refresh the page and try again", {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    });
                    this.loading = false
                }
            })
        },
        displayDate(date){
            var result = moment(date).format("YYYY-MM-DD");
            return result;
        },
        getHolidays() {
            axios.get('sales-and-purchase/holidays/range').then((response) => {
                this.holidays = response.data.data.map((d) => {
                let date = new Date(d.holidayDate);
                return {
                    month: date.getMonth(),
                    day: Number.parseInt(String(date.getDate()).padStart(2, '0'))
                }
                });
            }).catch((e) => {
                console.log(e)
            })
        },
        filterDisableDate(date, arrayDate) {
            let day = date.getDay();
            let now = new Date();

            // Disable All Sunday Days
            // No Delivery On Sunday
            if (day === 0) {
                return true;
            }

            // Disable Delivery Today
            let checkedDay = Number.parseInt(String(date.getDate()).padStart(2, '0'));
            if (checkedDay == this.today) {
                return true;
            }

            // Disable Holiday Day
            let month = date.getMonth();
            let checkElem = this.holidays.filter((h) => {
                if (h.month == month && h.day == checkedDay) {
                return true;
                }

                return false;
            })
            if (checkElem.length > 0) {
                return true;
            }
            // End Disable Holiday Day

            return false;
        },
        datePicked(date) {
            // Rule 1: Delivery Or Collect Date For Today Is Disable
            // Rule 2: Delivery Can Only Happen Next Day Or The Day After
            // Rule 3: Time Slot Option Is Tied To Time When Order Created
            // Rule 4: Time Order Created After Afternoon (18:00-00:00) Timeslot Available Is Next Day Morning
            // Rule 5: Time Order Created At Morning (00:00 - 12:00) Timeslot Available Is Afternoon Slot Next Day
            // Rule 6: Saturday (Half Day ?) And Sunday Is Off Day (No Delivery Happen Here)
            // Rule 7: If Today Is Friday And Choose Delivery Date At Saturday Only Morning Slot Available
            let selectedDate = moment(date);
            let selectedDay = selectedDate.days();

            let now = moment();
            let currentHour = now.format('HH');
            let currentDay = now.days();
            let currentDate = now.date();

            let predictDate = moment(now).add(1, 'days');
            let isNextDay = predictDate.date() === selectedDate.date() ? true : false;

            if (selectedDay === 6) {
                this.timeslotOptions = [
                'Morning: 9AM - 12PM',
                ];

                return;
            }

            if (!isNextDay) {
                this.timeslotOptions = [
                'Morning: 9AM - 12PM',
                'Afternoon: 1PM - 3PM',
                'Afternoon: 3PM - 6PM'
                ];
                return;
            }

            // Order Created At Night (Only Morning Slot Should Available) Next Day
            if (currentHour >= 18 && currentHour <= 23) {
                this.timeslotOptions = [
                'Morning: 9AM - 12PM'
                ];

                return;
            }

            // Order Created At Morning (Including The Night) Afternoon Slot Available Next Day
            if (currentHour <= 12) {
                this.timeslotOptions = [
                'Afternoon: 1PM - 3PM',
                'Afternoon: 3PM - 6PM'
                ];

                return;
            }

            // Order Created After Morning
            if (currentHour > 12) {
                this.timeslotOptions = [
                'Morning: 9AM - 12PM'
                ];

                return;
            }
        },
        async getDraftDeliveryDetail(){
            let sapDo = this.$route.params.id;

            
            
            this.draftDelivery = await this.$store.dispatch("delivery/getDeliveryOrderByDoNumber",sapDo)

            return new Promise((resolve, reject) => {
                //  setTimeout(() => {
                    resolve({
                        items: this.draftDelivery.DO_details
                    })
                // }, 2000)
            })
        },
        getSummary(material) {
            let itemSummary = this.summary[material];
            if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
            return itemSummary;
        },
        
        mapBarcode(data){
            var temp = [];
            
            this.scanItemTables.forEach(element => {
                if(element.material===data){
                    temp.push(element.scannedBarcode);
                }
            });
            var result = temp.filter(element=>{return element!=""})
            
            return result;
        },
        getItem(data){
            var searchItem = this.itemCatalog.find(x=>{return x._id==data}); 
            var result;
            if(searchItem===undefined){
                result  = {
                    itemModel:" "
                }
            }else{
                result = searchItem;
            }
            // console.log (result);
            return result;
        },
    },
    computed: {
        // deliveryOrders() {
        //     return this.$store.getters["delivery/getDO"];
        // },
        summary() {
            return this.scanItemTables.reduce((prev, next) => {

                // console.log("ini prev", prev);
                // console.log("ini next", next);

                if (prev[next.material] === undefined) {
                    prev[next.material] = { scanQty: 0, emptyQty: 0 };
                }

                if (next.scannedBarcode === '') {
                    prev[next.material].emptyQty += 1;
                } else {
                    prev[next.material].scanQty += 1;
                }
                return prev;
            }, {})
        },
        availability(){
            var searchBarcode = this.scanItemTables.map(element=>{return element.scannedBarcode})
            var nextStep = false;
            if(searchBarcode.includes("")){
                nextStep = false
            }else{
                nextStep = true
            }
            
            return nextStep
        }
    }
}
</script>
